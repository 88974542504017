'use client';
import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

type ErrorContextType = {
  error: ReactNode | Error;
  setError: Dispatch<SetStateAction<ReactNode>>;
};

const ErrorContext = createContext<ErrorContextType>({
  error: null,
  setError: () => {}, // placeholder function
});

export const useError = () => useContext(ErrorContext);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setError] = useState<ReactNode>(null);

  return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>;
};
