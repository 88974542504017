export const i18nConfig = {
  locales: ['en', 'de'],
  defaultLocale: 'de',
  localeCookie: "i18next"
};

export type Language = {
    key: string;
    name: string;
}

export const Languages: Language[] = [
  {key:"de", name:"deutsch"}, {key:"en", name:"English"}
]