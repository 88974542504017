// components/AlertBox.tsx

import React, { useState, useEffect, ReactNode } from 'react';

interface AlertBoxProps {
  type: 'info' | 'alert' | 'success';
  message?: string;
  children?: ReactNode;
  timer?: number; // Omit for a persistent notification
  onDismiss?: () => void; // Optional callback for when the alert is dismissed
}

const AlertBox: React.FC<AlertBoxProps> = ({ type, message, children, timer, onDismiss }) => {
  const [isVisible, setIsVisible] = useState(true);

  // Dismiss function that handles visibility and calls onDismiss callback
  const dismiss = () => {
    setIsVisible(false);
    if (onDismiss) {
      onDismiss();
    }
  };

  // Automatically dismiss alert after specified time
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    if (timer && isVisible) {
      timeoutId = setTimeout(dismiss, timer);
    }
    // Cleanup function
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timer, isVisible]);

  if (!isVisible) return null;

  // Define alert based on type
  const renderAlert = () => {
    switch (type) {
      case 'info':
        return (
          <div
            className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 w-fit"
            role="alert"
          >
            {message && <p className="font-bold">{message}</p>}
            {children}
          </div>
        );

      case 'alert':
        return (
          <div role="alert">
            {message && (
              <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2 w-fit">
                {message}
              </div>
            )}
            <div className="border border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
              {children}
            </div>
          </div>
        );

      case 'success':
        return (
          <div
            className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mx-auto w-fit"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="fill-current h-6 w-6 text-teal-500 mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                {message && <p className="font-bold">{message}</p>}
                {children}
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return renderAlert();
};

export default AlertBox;
