import React from 'react';

export type IconContainerProps = {
    Icon: React.ElementType; // This will accept any React component.
    iconProps?: React.SVGProps<SVGSVGElement>; // Optional props to pass to the SVG component.
}

export const IconContainer: React.FC<IconContainerProps> = ({ Icon, iconProps }) => {
    return <span className="icon-container">
        <Icon {...iconProps} />
    </span>;
};