import EventEmitter from 'events';
import { LogEntry } from './LogManager';
import { extractAfterSubstring } from '../utils/helper';


export class Logger {
  private logManager: EventEmitter;
  private minLevel: number;
  private module: string;
  private nextpath?: string;
  private readonly levels: { [key: string]: number } = {
    trace: 1,
    debug: 2,
    info: 3,
    warn: 4,
    error: 5,
    critical: 6,
  };

  constructor(logManager: EventEmitter, module: string, minLevel: string, nextpath?: string) {
    this.logManager = logManager;
    this.module = module;
    this.minLevel = this.levelToInt(minLevel);
    this.nextpath = nextpath;
  }

  /**
   * Converts a string level (trace/debug/info/warn/error) into a number
   *
   * @param minLevel
   */
  private levelToInt(minLevel: string): number {
    if (minLevel.toLowerCase() in this.levels) return this.levels[minLevel.toLowerCase()];
    else return 99;
  }

  /**
   * Central logging method.
   * @param logLevel
   * @param msg
   * @param payload
   */
  private log(logLevel: string, msg?: string, payload?: any, levelOneUp: boolean = false): void {
    const level = this.levelToInt(logLevel);
    if (level < this.minLevel) return;

    const logEntry: LogEntry = {
      level: logLevel,
      module: this.module,
      nextpath: this.nextpath,
      msg,
      payload,
    };

    // // Obtain the line/file through a thoroughly hacky method
    // // This creates a new stack trace and pulls the caller from it.  If the caller
    // // if .trace()
    // const error = new Error('');
    // if (error.stack) {
    //   const cla = error.stack.split('\n');
    //   let idx = 1;
    //   while (idx < cla.length && cla[idx].includes('at Logger.Object.')) idx++;
    //   if (idx < cla.length) {
    //     logEntry.location = cla[idx].slice(cla[idx].indexOf('at ') + 3, cla[idx].length);
    //   }
    // }

    const stack = new Error().stack;
    if (stack) {
      const stackLines = stack.split('\n').slice(!levelOneUp ? 4 : 4); // Adjust slice as needed

      const callerLine = stackLines[0] || '';
      const location = parseStackTraceLine(callerLine);
      logEntry.location = `${location}`;
    } else {
      // Fallback logging if stack trace is not available
      logEntry.location = `unknown`;
    }

    this.logManager.emit('log', logEntry);
  }

  public trace(msg?: string, payload?: any): void {
    this.log('trace', msg, payload);
  }
  public debug(msg?: string, payload?: any): void {
    this.log('debug', msg, payload);
  }
  public info(msg?: string, payload?: any): void {
    this.log('info', msg, payload);
  }
  public warn(msg?: string, payload?: any): void {
    this.log('warn', msg, payload);
  }
  public error(msg?: string, payload?: any, levelOneUp: boolean = false): void {
    this.log('error', msg, payload);
  }
  public critical(msg?: string, payload?: any): void {
    this.log('critical', msg, payload);
  }
}

function parseStackTraceLine(stackLine: string): string {
  // Modify this function based on the specific format of your stack traces
  // This is a simplistic parser; consider using a robust library for real-world applications
  const atPosition = stackLine.indexOf('at ');
  if (atPosition > -1) {
    const path = stackLine.substring(atPosition + 3);
    return path.replace(/webpack-internal:\/\/\//, ''); // Adjust based on bundler specifics
  }
  return 'Unknown location';
}

export function extractPath(path: string) {
  return extractAfterSubstring(path, '.next');
}

export function logError(logger: Logger, errorMessage: string, error: any) {
  // Check if error is an instance of Error and log its details
  if (error instanceof Error) {
    logger.error(
      errorMessage,
      {
        error: { message: error.message, stack: error.stack },
      },
      true
    );
  } else {
    // Handle non-Error throwables, if necessary
    logger.error(
      errorMessage,
      {
        error: { message: 'An unknown error occurred', details: error },
      },
      true
    );
  }
}
