export const isServer = typeof window === 'undefined';

export function extractAfterSubstring(fullString: string, substring: string): string {
  const index = fullString.indexOf(substring);
  if (index !== -1) {
    // Add the length of the substring to start slicing after it
    return fullString.slice(index + substring.length);
  }
  // Return an empty string or possibly the full path if the substring isn't found
  return fullString;
}

export function arrayCeil(arr: any[], number: number) {
  const sorted = arr.sort((a, b) => a - b);

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] > number) {
      return sorted[i];
    }
  }

  // If no index found return the last element
  return sorted[sorted.length - 1];
}
